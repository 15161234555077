import React, {useMemo, useState} from "react";
import GenericParticles from "../components/GenericParticles";
import VideoPlayer from "../components/VideoPlayer";

type Inputs = {
  username: string,
  password: string,
};

function EventOver() {
  return (<>
    <GenericParticles />

    <div className="EventOver">
      <div className="EventOver_Background" style={{ backgroundImage: 'url("/media/event_over.png")'}}></div>

      <div className="EventOver_Content" >
        <div className="EventOver_Info" >
          <h1>International conference Future X 2021 has ended.</h1>
          <h2>You can still watch the replay here.</h2>
        </div>

        <VideoPlayer />
      </div>
    </div>
  </>);
}

export default EventOver;
