import axios, {Axios, AxiosResponse} from "axios";
import jwtDecode from "jwt-decode";
import {useEffect, useMemo} from "react";
import {useRecoilState} from "recoil";
import {
  DecodedJWTToken,
  EmojiCodename,
  EmojiRequestPayload,
  LoginPayload,
  LoginResponse,
  RefreshTokenResponse, SpinResult, Stats
} from "../types/api";
import {refreshTokenAtom, tokenAtom, userAtom} from "../state/auth.recoil";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

export default function useApi() {
  const [token, setToken] = useRecoilState(tokenAtom);
  const [refreshToken, setRefreshToken] = useRecoilState(refreshTokenAtom);
  const [user, setUser] = useRecoilState(userAtom);

  const headers = useMemo(() => ({
    headers: {Authorization: `Bearer ${token}`},
  }), [token])

  class AuthService {
    public login(payload: LoginPayload): Promise<AxiosResponse<LoginResponse>> {
      return axios.post("/auth/login", payload);
    }

    public refresh(): Promise<AxiosResponse<RefreshTokenResponse>> {
      return axios.post("/auth/refresh", {}, {headers: {Authorization: `Bearer ${refreshToken}`}});
    }

    public destroy() {
      // console.log("Destroyed session");
      localStorage.removeItem("token");
      localStorage.removeItem("refreshToken");
      setToken(null);
      setRefreshToken(null);
      setUser({} as DecodedJWTToken);
    }
  }

  class LiveService {
    public stats(): Promise<AxiosResponse<Stats>>  {
      return axios.get("/stats", headers);
    }

    public spin(): Promise<AxiosResponse<SpinResult>>  {
      return axios.get("/awards/spin", headers);
    }
  }

  class AdminService {
    public trigger(payload: EmojiCodename): Promise<AxiosResponse<EmojiRequestPayload>>  {
      return axios.post("/emoji/trigger", {emojiName: payload}, headers);
    }
  }

  return {
    auth: new AuthService(),
    live: new LiveService(),
    admin: new AdminService(),
  }
}

export function decodeToken(token: string): DecodedJWTToken {
  return jwtDecode(token);
}

export function isTokenValid(token: string): boolean {
  const decoded = decodeToken(token);
  return Date.now() < decoded.exp * 1000;
}