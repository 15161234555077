import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {RecoilRoot} from "recoil";

import './stylesheets/global.scss';
import './stylesheets/nav.scss';
import './stylesheets/panels.scss';
import './stylesheets/videoplayer.scss';
import './stylesheets/loaders.scss';
import './stylesheets/game.scss';
import './stylesheets/reel.scss';
import './stylesheets/login.scss';
import './stylesheets/inputs.scss';
import './stylesheets/cookies.scss';

ReactDOM.render(
  <React.StrictMode>
    <RecoilRoot>
      <App />
    </RecoilRoot>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
