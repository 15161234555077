import {useRecoilState, useRecoilValue} from "recoil";
import {refreshTokenAtom, tokenAtom, userAtom} from "../state/auth.recoil";
import useApi, {decodeToken, isTokenValid} from "./useApi";
import {useEffect, useState} from "react";
import {useInterval} from "react-use";

function useTokenRefresher() {
  const api = useApi();
  const [loading, setLoading] = useState(true);
  const [token, setToken] = useRecoilState(tokenAtom);
  const [user, setUser] = useRecoilState(userAtom);

  const rt = useRecoilValue(refreshTokenAtom);

  // useEffect(() =>{
  //   console.log('-----------------')
  //   console.log(rt)
  //   console.log(token)
  // }, [rt, token])

  async function refreshToken() {
    try {
      // console.log("Refreshing token");
      const {access_token} = (await api.auth.refresh())?.data
      localStorage.setItem("token", access_token);
      // console.log('refreshed token successfully')
      setToken(access_token);
    } catch (e) {
      console.log("Encountered an error while refreshing the token.");
      api.auth.destroy();
    }
  }

  // Validate old token on a new session
  useEffect(() => {
    if (token) {
      if (!isTokenValid(token)) {
        api.auth.destroy();
        // enqueueSnackbar("Your session expired.", {variant: "error"});
      } else {
        refreshToken();
        setUser(decodeToken(token));
      }
    }
    setLoading(false);
  }, [])

  // Check if token is still valid and refresh it regularly
  useInterval(async () => {
    if (!token) return;
    // if (!isTokenValid(token)) api.auth.destroy()
    else refreshToken();
  }, 3 * 60 * 1000);

};

export default useTokenRefresher;
