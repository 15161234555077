import YouTube from "react-youtube";

function VideoPlayer() {
  return (
    <div className="VideoPlayer">
      {/*<iframe className="VideoPlayer_Player" src="https://player.vimeo.com/video/415788997?h=b0aa3fb137&title=0&byline=0&portrait=0&autoplay=1&muted=1" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" ></iframe>*/}
      {/*<iframe className="VideoPlayer_Player"  src="https://player.vimeo.com/video/26958624?h=69046ca31d&autoplay=1&title=0&byline=0&portrait=0" frameBorder="0" allow="autoplay; fullscreen;picture-in-picture" ></iframe>*/}

      <YouTube
          className="VideoPlayer_Player"
          videoId={'6vu3trpJBbg'}
          opts={{
            playerVars: {
              autoplay: 1,
              rel: 0,
              modestbranding: 1,
              controls: 1,
            }
          }}
      />
    </div>
  );
}

export default VideoPlayer;
