import {useRecoilState, useRecoilValue} from "recoil";
import {userAtom} from "./state/auth.recoil";
import LoginPage from "./pages/LoginPage";
import MainPage from "./pages/MainPage";
import useTokenRefresher from "./hooks/useTokenRefresher";
import Cookies from "./components/Cookies";
import EventOver from "./pages/EventOver";

function App() {
  const tr = useTokenRefresher();
  const auth = useRecoilValue(userAtom);

  return <>
    {/*{auth?.email ? <MainPage /> : <LoginPage />}*/}
    <EventOver />
    <Cookies />
  </>
}

export default App;
