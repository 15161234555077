import React, {useMemo} from 'react';
import Particles from "react-tsparticles";
import {loadLinksPreset} from "tsparticles-preset-links";
import useMediaQuery from "../hooks/useMediaQuery";

export default function GenericParticles() {
  const smallScreen = useMediaQuery('(max-width: 1280px)');

  const render = useMemo(() => <Particles
      init={(main) => loadLinksPreset(main)}
      options={{
        background: {
          color: {
            value: "transparent",
          },

        },
        interactivity: {
          events: {
            resize: true
          }
        },
        preset: "links"
      }}
  />, [])

  return smallScreen ? null : <div style={{opacity: 0.4}}>
    {render}
  </div>
}